interface Props {
  label: string;
  value?: string | number;
  input?: React.ReactNode;
}

const UserProfileItem: React.FC<Props> = ({
  label,
  value,
  input,
}) => {
  return (
    <div>
      <span className="d-block font-yettel-light">{label}</span>
      <span className="d-block mt-2 mb-2">
        {!input && <>{value}&nbsp;</>}
        {input && input}
      </span>
    </div>
  )
}

export default UserProfileItem;