import { Navigate } from "react-router-dom";
import { useState } from "react";
import useFetchTokensFromRefreshToken from "../hooks/useFetchTokensFromRefreshToken"
import useFetchUserData from "../hooks/useFetchUserData";
import CheckConsent from "./CheckConsent";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorageKeys";

const RefreshTokensFetchUserDataAndCheckConsent = () => {
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || "";
  const [refreshTokenRefetchCount, setRefreshTokenRefetchCount] = useState<number>(0);
  const [userDataRefetchCount, setUserDataRefetchCount] = useState<number>(0);

  const { isSuccess: tokensRefreshed, isError: errorRefreshingTokens, refetch: refetchTokens } = useFetchTokensFromRefreshToken(refreshToken);
  const { isSuccess: userDataFetched, isError: errorFetchingUserData, refetch: refetchUserData } = useFetchUserData(tokensRefreshed);

  if (errorRefreshingTokens && refreshTokenRefetchCount < 3) {
    setRefreshTokenRefetchCount(refreshTokenRefetchCount + 1);
    refetchTokens();
    return <></>
  }

  if (errorFetchingUserData && userDataRefetchCount < 3) {
    setUserDataRefetchCount(userDataRefetchCount + 1);
    refetchUserData();
    return <></>
  }

  if (errorRefreshingTokens || errorFetchingUserData) {
    return <Navigate to={'/greska-prilikom-autorizacije'} />;
  }

  return (
    <>
      {userDataFetched && <CheckConsent loginOnError={true} />}
    </>
  )
}

export default RefreshTokensFetchUserDataAndCheckConsent;
