import Button from './Button';
import checkmark from '../assets/checkmark.png';
import { MouseEventHandler, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { DisplayedIn, Offer, OfferSaved, YSH_GTM_EVENTS } from '../types/types';
import { callPublicAPI } from '../utils/api';
import { mapOfferToAnalyticsObject, pushToAnalytics } from '../utils/analytics';

interface Props {
  offer: Offer;
  displayedIn: DisplayedIn;
}

const OfferActionSave: React.FC<Props> = ({ offer, displayedIn }) => {
  const button = useRef<HTMLButtonElement>(null);
  const queryClient = useQueryClient();

  const handleOfferSaveFail = () => {
    alert("Žao nam je, nismo uspeli da sačuvamo kupon");
  }

  const handleSaveOfferSuccess = async (response: OfferSaved) => {
    if (!response) return handleOfferSaveFail();
    button.current?.classList.add('button--just-saved');

    setTimeout(async () => {
      await queryClient.invalidateQueries({
        predicate: query =>
          query.queryKey[0] === QUERY_KEYS.GET_OFFERS ||
          (query.queryKey[0] === QUERY_KEYS.GET_OFFER && query.queryKey[1] === offer.id)
      });

      const innerFakeButton = button.current?.querySelector('.button--just-saved-button');
      innerFakeButton?.remove();
    }, 600);

    pushToAnalytics(YSH_GTM_EVENTS.DOWNLOAD_COUPON, { coupon_details: [mapOfferToAnalyticsObject(offer, displayedIn)] });
  };

  const saveOffer = useMutation<OfferSaved>({
    mutationKey: [QUERY_KEYS.SAVE_OFFER, offer.id],
    mutationFn: async () => {
      const response = await callPublicAPI<OfferSaved>('PUT', `/offers/${offer.id}/code`);
      return response.data;
    },
    onSuccess: handleSaveOfferSuccess,
    onError: handleOfferSaveFail
  });


  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();

    saveOffer.mutate();
  };

  return (
    <>
      <Button ref={button} onClick={handleClick}>
        Sačuvaj kupon
      </Button>
      <Button className="button--just-saved-button">
        <img width={16} height={16} src={checkmark} alt="coupon saved" className="me-1" />
        <span>Kupon sačuvan</span>
      </Button>
    </>
  );
};

export default OfferActionSave;
