import "../scss/loading-indicator.scss";

const LoadingIndicator = () => {
  return (
    <div className="mb-2">
      <div className="loading-indicator"></div>
    </div>
  )
}

export default LoadingIndicator;