import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { Category, CategoryWithCount } from '../types/types';
import CategoryCheckboxWithImage from './CategoryCheckboxWithImage';
import Button from './Button';
import { useEffect } from 'react';
import "../scss/map-category-picker.scss";

interface Props {
  handleConfirm: () => void;
  categories: CategoryWithCount[];
  selectedCategoryIDs: string[];
  setSelectedCategoryIDs: (value: string[]) => void;
}

const MapCategoryPickerContent: React.FC<Props> = ({
  handleConfirm,
  categories,
  selectedCategoryIDs,
  setSelectedCategoryIDs
}) => {
  const [searchParams] = useSearchParams();
  const selectedCategoryNames = searchParams.get(SEARCH_PARAMS.CATEGORIES)?.split(',') || [];

  const handleCategoryClick = (category: Category) => {
    const uniqueCategoryIDs = new Set(selectedCategoryIDs);
    uniqueCategoryIDs.has(category.id) ? uniqueCategoryIDs.delete(category.id) : uniqueCategoryIDs.add(category.id);

    setSelectedCategoryIDs(Array.from(uniqueCategoryIDs));
  };

  useEffect(() => {
    const agreggateSelectedCategories = () => {
      const uniqueCategoryIDs = new Set(selectedCategoryIDs);
      const categoriesSelectedInSearchParams =
        categories?.filter(category => selectedCategoryNames?.includes(category.name)) || [];

      categoriesSelectedInSearchParams.forEach(category => {
        uniqueCategoryIDs.add(category.id);
      });

      setSelectedCategoryIDs(Array.from(uniqueCategoryIDs));
    };

    if (categories?.length) {
      agreggateSelectedCategories();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <CategoryList
      categories={categories}
      selectedCategoryIDs={selectedCategoryIDs ?? []}
      handleCategoryClick={handleCategoryClick}
      handleConfirm={handleConfirm}
    />
  );
};

interface CategoryListProps {
  categories: Category[];
  selectedCategoryIDs: string[];
  handleCategoryClick: (category: Category) => void;
  handleConfirm: () => void;
}

const CategoryList: React.FC<CategoryListProps> = ({
  categories,
  selectedCategoryIDs,
  handleCategoryClick,
  handleConfirm
}) => {
  return (
    <section className="map-category-picker-list mt-3 mb-3">
      {categories.map(category => (
        <CategoryCheckboxWithImage
          key={category.id}
          category={category}
          categoriesMarkedSelected={selectedCategoryIDs}
          handleCategoryClick={handleCategoryClick}
        />
      ))}

      <Button className="mt-5" onClick={handleConfirm}>
        Prikaži oglase
      </Button>
    </section>
  );
};

export default MapCategoryPickerContent;
