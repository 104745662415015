import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorageKeys";
import { removeUserDataFromLocalStorage } from "../utils/auth";
import { GetRefreshTokenRequestData, Tokens } from "../types/types";
import { QUERY_KEYS } from "../constants/queryKeys";
import { callNalogAPI } from "../utils/api";

const useFetchTokensFromRefreshToken = (refreshToken: string) => {
  const [hasErrored, setHasErrored] = useState(false);

  const { isSuccess, isError, refetch } = useQuery<Tokens>({
    queryKey: [QUERY_KEYS.GET_TOKENS_FROM_REFRESH_TOKEN, refreshToken],
    queryFn: async () => {
      setHasErrored(false);

      const data: GetRefreshTokenRequestData = {
        client_id: "market",
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };

      const response = await callNalogAPI<Tokens>("POST", "/token", data);

      const { access_token, refresh_token } = response;

      if (!access_token || !refresh_token) {
        setHasErrored(true);
      } else {
        localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, access_token);
        localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refresh_token);
      }

      return response;
    },
  });

  useEffect(() => {
    if (isError || hasErrored) {
      removeUserDataFromLocalStorage();
    }
  }, [isError, hasErrored]);

  return {
    isSuccess,
    isError: isError || hasErrored,
    refetch
  }
}

export default useFetchTokensFromRefreshToken;
