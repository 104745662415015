export const NALOG_API = import.meta.env.VITE_NALOG_API;
export const NALOG = import.meta.env.VITE_NALOG;
export const PUBLIC_API = import.meta.env.VITE_PUBLIC_API;
export const IMAGE_BASE_URL = import.meta.env.VITE_IMAGE_BASE_URL;
export const LOGIN_REDIRECT = import.meta.env.VITE_LOGIN_REDIRECT;
export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
export const APP_BASE = import.meta.env.VITE_APP_BASE;
export const SAVE_ALL_COUPONS_VISIBLE = import.meta.env.VITE_SAVE_ALL_COUPONS_VISIBLE
export const DAILY_PRIZE_MODAL_VISIBLE = import.meta.env.VITE_DAILY_PRIZE_MODAL_VISIBLE
export const END_OF_GAME_MODAL_VISIBLE = import.meta.env.VITE_END_OF_GAME_MODAL_VISIBLE 
export const GAME_RESULTS_MODAL_VISIBLE = import.meta.env.VITE_GAME_RESULTS_MODAL_VISIBLE

