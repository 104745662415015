import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import GlobalLoadingIndicator from './GlobalLoadingIndicator';
import { QUERY_KEYS } from '../constants/queryKeys';
import { useSearchParams } from 'react-router-dom';
import { logIn, persistAuthorizationDataToLocalStorage, authorizeWithJWEToken } from '../utils/auth';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import Authorize from './Authorize';
import '../scss/_main.scss';
import { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

const GlobalWrapper: React.FC<Props> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const autoLogin = searchParams.get(SEARCH_PARAMS.AUTO_LOGIN) || '';
  const jweToken = searchParams.get(SEARCH_PARAMS.TOKEN) || '';
  const redirected = searchParams.get(SEARCH_PARAMS.REDIRECTED) === 'true' || false;

  const isMutating = useIsMutating({
    predicate: mutation => {
      const mutationKey =
        mutation.options.mutationKey && mutation.options.mutationKey[0] ? mutation.options.mutationKey[0] : '';

      // List of mutations that are exempt from global loading.
      return ![QUERY_KEYS.SAVE_OFFER].includes(mutationKey as string);
    }
  });

  const isFetching = useIsFetching({
    predicate: query => {
      // List of queries that are exempt from global loading.
      return ![
        QUERY_KEYS.GET_LOCATIONS,
        QUERY_KEYS.GET_OFFERS,
        QUERY_KEYS.GET_SEARCH_SUGGESTIONS,
        QUERY_KEYS.GET_OFFER_PARTNER_LOCATION,
        QUERY_KEYS.GET_OFFER_PARTNER_LOCATIONS_LIST,
        QUERY_KEYS.GET_USER_LOCATION,
        QUERY_KEYS.GET_SHOPPING_MALLS,
        QUERY_KEYS.GET_PARTNERS_WITH_COUNT
      ].includes(query.queryKey[0] as string);
    }
  });

  if (autoLogin) {
    searchParams.delete(SEARCH_PARAMS.AUTO_LOGIN);
    setSearchParams(searchParams);

    logIn();

    return <></>;
  }

  if (jweToken) {
    authorizeWithJWEToken(jweToken)
      .then(response => {
        persistAuthorizationDataToLocalStorage(response.data);

        searchParams.delete(SEARCH_PARAMS.TOKEN);
        setSearchParams(searchParams);
      })
      .catch(error => {
        console.log(error);
        searchParams.delete(SEARCH_PARAMS.TOKEN);
        setSearchParams(searchParams);
      })
      .finally(() => {
        window.location.reload();
      });

    return <></>;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {!autoLogin && !jweToken && (
        <>
          <Authorize redirected={redirected} />
        </>
      )}

      {children}

      {(isFetching > 0 || isMutating > 0) && <GlobalLoadingIndicator />}
    </>
  );
};

export default GlobalWrapper;
