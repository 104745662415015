import { DisplayedIn, Offer } from '../types/types';
import OfferActionSave from './OfferActionSave';
import OfferActionSaveLoggedOut from './OfferActionSaveLoggedOut';
import OfferActionUseCoupon from './OfferActionUseCoupon';

interface Props {
  offer: Offer;
  isUserLoggedIn: boolean;
  displayedIn: DisplayedIn;
}

const OfferAction: React.FC<Props> = ({ offer, isUserLoggedIn, displayedIn }) => {
  const showUseCoupon = (isUserLoggedIn && offer.code) || displayedIn === "Aktivni kuponi";

  return (
    <div className="position-relative">
      {showUseCoupon && <OfferActionUseCoupon offerId={offer.id} />}

      {isUserLoggedIn && !showUseCoupon && <OfferActionSave offer={offer} displayedIn={displayedIn} />}

      {!isUserLoggedIn && <OfferActionSaveLoggedOut offer={offer} displayedIn={displayedIn} />}
    </div>
  );
};

export default OfferAction;
