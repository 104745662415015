interface Props {
  children?: React.ReactNode;
}

const ErrorMessage: React.FC<Props> = ({ children }) => {
  console.log(children);
  
  return (<></>)
  // return (
  //   <div className="mb-2">
  //     {children}
  //   </div>
  // );
}

export default ErrorMessage;