import { GoogleMap } from '@react-google-maps/api';
import { DEFAULT_OPTIONS } from '../constants/googleMapConstants';
import { MapStore, Offer } from '../types/types';
import currentLocationIcon from '../assets/current-user-location.png';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import StoresMarkerClustererCustomRenderer from './StoresMarkerClustererCustomRenderer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { VisitedMarkerEnums } from '../constants/googleMapMarkersVisited';
import { MarkerEnums } from '../constants/googleMapMarkers';
import '../scss/map-page-map.scss';

interface Props {
  categories: string[];
  partners: string[];
  mapCenter: google.maps.LatLngLiteral | null;
  setMapCenter: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | null>>;
  mapZoom: number;
  setMapZoom: React.Dispatch<React.SetStateAction<number>>;
  offers: Offer[];
}

const DEFAULT_FAR_ZOOM = 7;
const DEFAULT_CLOSE_ZOOM = 13;

const FilteredMap: React.FC<Props> = ({
  categories,
  partners,
  mapCenter,
  setMapCenter,
  mapZoom,
  setMapZoom,
  offers
}) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const initialMapCenter = mapCenter;
  const initialMapZoom = mapZoom;

  const getStoresFromOffers = (offers: Offer[]): MapStore[] => {
    const stores = new Map<string, MapStore>();

    offers?.forEach(offer => {
      offer.partner_locations.forEach((store: MapStore) => {
        if (offer.partner_id) store.partner_id = offer.partner_id;
        if (offer.partner_name) store.partner_name = offer.partner_name;
        if (offer.partner_category_name) store.partner_category_name = offer.partner_category_name;

        if (categories.length > 0 && partners.length === 0) {
          if (categories.includes(store.partner_category_name)) {
            stores.set(store.id, store);
          }
        }

        if (partners.length > 0 && categories.length === 0) {
          if (partners.includes(store.partner_id)) {
            stores.set(store.id, store);
          }
        }

        if (partners.length > 0 && categories.length > 0) {
          if (partners.includes(store.partner_id) && categories.includes(store.partner_category_name)) {
            stores.set(store.id, store);
          }
        }

        if (categories.length === 0 && partners.length === 0) {
          stores.set(store.id, store);
        }
      });
    });

    return Array.from(stores.values());
  };

  const addUserMarker = (position: GeolocationPosition, map: google.maps.Map) => {
    const { latitude, longitude } = position.coords;
    const userGeolocation = new google.maps.LatLng(latitude, longitude);
    const userMarker = new google.maps.Marker({
      position: userGeolocation,
      icon: currentLocationIcon,
      zIndex: 9999,
      animation: google.maps.Animation.DROP
    });

    userMarker.setMap(map);
  };

  const setStoreMarkers = (stores: MapStore[], map: google.maps.Map) => {
    const markers: google.maps.Marker[] = [];

    let filteredStores = stores;

    if (categories.length > 0) {
      filteredStores = stores.filter(store => categories.includes(store.partner_category_name));
    }

    filteredStores.map(store => {
      const { lat, lng } = store;
      const icon = MarkerEnums[store.partner_category_name];
      const visitedIcon = VisitedMarkerEnums[store.partner_category_name];

      const marker = new google.maps.Marker({
        position: { lat, lng },
        icon
      });

      marker.addListener('click', () => {
        marker.setIcon(visitedIcon);

        const urlParams = searchParams.toString();
        navigate(`/near-by/partner/${store.id}?${urlParams}`, { replace: true });
      });

      markers.push(marker);
    });

    const algorithm = new SuperClusterAlgorithm({ maxZoom: 12 });
    new MarkerClusterer({ map, markers, algorithm, renderer: new StoresMarkerClustererCustomRenderer() });
  };

  return (
    <GoogleMap
      id="map-page-map"
      onLoad={async map => {
        map.setOptions({
          ...DEFAULT_OPTIONS,
          zoom: initialMapZoom ?? DEFAULT_FAR_ZOOM,
          center: initialMapCenter ?? { lat: 0, lng: 0 }
        });

        const stores = getStoresFromOffers(offers);

        let permissionGranted: Partial<PermissionStatus> = { state: 'denied' };

        if (navigator && navigator.geolocation) {
          permissionGranted = await navigator.permissions.query({ name: 'geolocation' });
        }

        if (permissionGranted.state === 'granted') {
          navigator.geolocation.getCurrentPosition(position => {
            addUserMarker(position, map);
            setStoreMarkers(stores, map);

            if (mapCenter && mapZoom) {
              map.setCenter(mapCenter);
              map.setZoom(mapZoom);
            } else {
              map.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
              map.setZoom(DEFAULT_CLOSE_ZOOM);
            }
          });
        } else {
          navigator.geolocation.getCurrentPosition(position => {
            addUserMarker(position, map);
            map.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
            map.setZoom(DEFAULT_CLOSE_ZOOM);
          });

          setStoreMarkers(stores, map);

          if (mapCenter && mapZoom) {
            map.setCenter(mapCenter);
            map.setZoom(mapZoom);
          } else {
            map.setCenter({ lat: 44, lng: 21 });
            map.setZoom(7);
          }
        }

        map.addListener('zoom_changed', () => {
          setMapZoom(map.getZoom() as number);
        });

        map.addListener('center_changed', () => {
          setMapCenter(map.getCenter()?.toJSON() as google.maps.LatLngLiteral);
        });
      }}
    />
  );
};

export default FilteredMap;
