import { Navigate, useLocation } from 'react-router-dom';
import useFetchConsent from '../hooks/useFetchConsent';
import { isUserLoggedIn, shouldRedirectToPrivacySettings } from '../utils/auth';
import { useState } from 'react';

interface Props {
  loginOnError?: boolean;
}

const CheckConsent: React.FC<Props> = ({ loginOnError }) => {
  const isLoggedIn = isUserLoggedIn();
  const location = useLocation();
  const [refetchCount, setRefetchCount] = useState<number>(0);

  const shouldSkipFetchingConsent = !isLoggedIn || location.pathname === '/profil/privatnost';
  const { isFetched: consentsFetched, isError, refetch } = useFetchConsent(shouldSkipFetchingConsent);

  if (shouldSkipFetchingConsent) {
    return <></>;
  }

  if (isError && refetchCount < 3) {
    setRefetchCount(refetchCount + 1);
    refetch();
    return <></>
  }

  if (isError && refetchCount > 2) {
    if (!loginOnError) {
      return <Navigate to={'/greska-prilikom-autorizacije'} />;
    }
  }

  const shouldRedirect = isLoggedIn && consentsFetched && shouldRedirectToPrivacySettings();

  if (shouldRedirect) {
    return <Navigate to={`/profil/privatnost?callback=${location.pathname}`} />;
  }

  return <></>;
};

export default CheckConsent;
