import { useRouteError } from 'react-router-dom';
import errorImage from '../assets/error.png';
import Button from './Button';
import { logIn } from '../utils/auth';

interface Props {
  customMessage?: string;
  showLoginButton?: boolean;
}

const ErrorPage: React.FC<Props> = ({ customMessage, showLoginButton }) => {
  const error = useRouteError();
  console.log(error);

  const handleLogin = () => {
    logIn();
  }

  return (
    <div className='text-center' style={{ width: "100%" }}>
      <h1 className='fz-32 font-yettel-bold mb-2'>Uuups!</h1>
      <h2 className='fz-24 font-yettel mb-4'>Došlo je do greške.</h2>

      {customMessage && (<h2 className='fz-24 font-yettel mb-4'>{customMessage}</h2>)}
      {showLoginButton && (<Button onClick={handleLogin}>Prijavi se</Button>)}


      < img src={errorImage} alt="Greška" width={460} />
    </div>
  );
}

export default ErrorPage;
