import Sheet from 'react-modal-sheet';
import MapCategoryPickerContent from './MapCategoryPickerContent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import close from '../assets/images/icons/icon-close.svg';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { useQuery } from '@tanstack/react-query';
import { CategoryWithCount } from '../types/types';
import { callPublicAPI } from '../utils/api';
import { QUERY_KEYS } from '../constants/queryKeys';
import { STALE_TIME } from '../constants/staleTime';
import { useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import '../scss/map-category-picker.scss';

const MapCategoryPickerOverlay = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategoryIDs, setSelectedCategoryIDs] = useState<string[]>([]);

  const { isLoading, data: categories } = useQuery<CategoryWithCount[]>({
    queryKey: [QUERY_KEYS.GET_CATEGORIES_WITH_COUNT_ON_MAP],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        sort: 'abc',
        limit: '100',
        withCount: '1',
        onMap: '1'
      });
      const response = await callPublicAPI<CategoryWithCount[]>('GET', '/categories', searchParams);

      const categoriesWithOffers = response.data.filter(category => category.ad_count > 0);

      return categoriesWithOffers;
    },
    staleTime: STALE_TIME.HOUR
  });

  const handleConfirm = () => {
    const selectedCategoryNames = (
      categories?.filter(category => selectedCategoryIDs?.includes(category.id))?.map(category => category.name) || []
    ).join(',');

    if (selectedCategoryNames.length === 0) {
      searchParams.delete(SEARCH_PARAMS.CATEGORIES);
    } else {
      searchParams.set(SEARCH_PARAMS.CATEGORIES, selectedCategoryNames);
    }

    searchParams.delete(SEARCH_PARAMS.SHOW_MAP_CATEGORIES);
    setSearchParams(searchParams);

    const urlParams = searchParams.toString();

    navigate(`/near-by?${urlParams}`, { replace: true });
  };

  const handleClosePopup = () => {
    handleConfirm();
  };

  return (
    <Sheet isOpen={true} onClose={handleClosePopup} className="map-category-picker">
      <Sheet.Container>
        <Sheet.Header>
          <div className="d-flex justify-content-end ps-3 pe-3 pt-3">
            <img
              className="cursor-pointer"
              width={25}
              height={25}
              src={close}
              alt="close modal"
              onClick={handleClosePopup}
            />
          </div>
        </Sheet.Header>
        <Sheet.Content>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <LoadingIndicator />
            </div>
          )}

          {!isLoading && categories && categories.length === 0 && (
            <div className="ps-3 pe-3 pt-4 text-center">Trenutno nemamo ponuda koje se mogu iskoristiti na fizičkoj lokaciji </div>
          )}

          {!isLoading && categories && (
            <MapCategoryPickerContent
              handleConfirm={handleConfirm}
              categories={categories}
              selectedCategoryIDs={selectedCategoryIDs}
              setSelectedCategoryIDs={setSelectedCategoryIDs}
            />
          )}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={handleClosePopup} />
    </Sheet>
  );
};

export default MapCategoryPickerOverlay;
