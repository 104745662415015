import { useNavigate, useSearchParams } from "react-router-dom"
import { authorizeWithAccessAndRefreshTokens, getTokensFromCodeAndCodeVerifier, persistAuthorizationDataToLocalStorage } from "../utils/auth";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorageKeys";
import { SEARCH_PARAMS } from "../constants/searchParamNames";

const LoginRedirectPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get(SEARCH_PARAMS.CODE) ?? "";
  const state = searchParams.get(SEARCH_PARAMS.STATE) ?? "";
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEYS.STATE);
  const code_verifier = localStorage.getItem(LOCAL_STORAGE_KEYS.CODE_CHALLENGE_VERIFIER) ?? "";

  const redirectToPath = localStorage.getItem(LOCAL_STORAGE_KEYS.ATTEMPTED_VIEW) || "/";
  const redirectPathWithSearchParams = `${redirectToPath}?${SEARCH_PARAMS.REDIRECTED}=true`;

  getTokensFromCodeAndCodeVerifier(code, code_verifier).then(async (response) => {
    const { access_token, refresh_token } = response;
    localStorage.removeItem(LOCAL_STORAGE_KEYS.STATE);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CODE_CHALLENGE_VERIFIER);

    if (state === savedState) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, access_token);
      localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refresh_token);

      await authorizeWithAccessAndRefreshTokens(access_token, refresh_token).then((response) => {
        persistAuthorizationDataToLocalStorage(response.data);
      }).catch(() => {
        navigate(redirectPathWithSearchParams);
      });
    }
  }).finally(() => {
    navigate(redirectPathWithSearchParams);
  });

  return (
    <>
      Redirekcija sledi...
    </>
  )
}

export default LoginRedirectPage
