import { useQuery } from "@tanstack/react-query";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorageKeys";
import { persistAuthorizationDataToLocalStorage, removeUserDataFromLocalStorage } from "../utils/auth";
import { callPublicAPI } from "../utils/api";
import { useEffect, useState } from "react";
import { QUERY_KEYS } from "../constants/queryKeys";
import { ApiErrorResponse, ApiResponse, Authorization, isApiErrorResponse } from "../types/types";

const useFetchUserData = (fetchUserData: boolean | undefined) => {
  const [hasErrored, setHasErrored] = useState(false);

  const accessTokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";
  const refreshTokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || "";

  const { data: authorizationResponse, isSuccess, isError, refetch } = useQuery<ApiResponse<Authorization> | ApiErrorResponse>({
    queryKey: [QUERY_KEYS.AUTHORIZE_WITH_TOKENS, accessTokenFromLocalStorage, refreshTokenFromLocalStorage],
    queryFn: async () => {
      setHasErrored(false);

      const accessTokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";
      const refreshTokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || "";

      const data = {
        access_token: accessTokenFromLocalStorage,
        refresh_token: refreshTokenFromLocalStorage,
      };

      const response = await callPublicAPI<Authorization>("POST", "/auth", null, data);

      if (isApiErrorResponse(response)) {
        setHasErrored(true);
        return response;
      }

      return response;
    },
    enabled: fetchUserData,
  });

  useEffect(() => {
    if (authorizationResponse && !isApiErrorResponse(authorizationResponse)) {
      persistAuthorizationDataToLocalStorage(authorizationResponse.data);
    }
  }, [isSuccess, authorizationResponse]);

  useEffect(() => {
    if (isError || hasErrored) {
      removeUserDataFromLocalStorage();
    }
  }, [isError, hasErrored]);

  return {
    isSuccess,
    isError: isError || hasErrored,
    refetch
  }
}

export default useFetchUserData;
