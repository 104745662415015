import { useState } from "react";
import useFetchUserData from "../hooks/useFetchUserData";
import CheckConsent from "./CheckConsent";

const FetchUserDataAndCheckConsent = () => {
  const [refetchCount, setRefetchCount] = useState<number>(0);
  const { isSuccess: userDataFetched, isError, refetch } = useFetchUserData(true);

  if (isError && refetchCount < 3) {
    setRefetchCount(refetchCount + 1);
    refetch();
    return <></>
  }

  return (
    <>
      {userDataFetched && <CheckConsent loginOnError={true} />}
    </>
  )
}

export default FetchUserDataAndCheckConsent;
