import { Helmet } from "react-helmet";
import { isUserLoggedIn, logIn } from "../utils/auth";
import OfferOverlayGroup from "./OfferOverlayGroup";
import UserOffersPage from "./UserOffersPage";
import FullWidthPageContent from "./FullWidthPageContent";
import PageTitle from "./PageTitle";
import Button from "./Button";
import "../scss/yettel-content.scss"

const CouponsPage = () => {
  const isLoggedIn = isUserLoggedIn();

  const handleLogin = () => {
    logIn();
  }

  return (
    <>
      <Helmet>
        <title>Kuponi | Yettel Shopping</title>
      </Helmet>
      <FullWidthPageContent>
        <PageTitle className="mb-4">Moji kuponi</PageTitle>
        <div className="mx-auto">
          {isLoggedIn && (
            <>
              <UserOffersPage />
              <OfferOverlayGroup />
            </>
          )}

          {!isLoggedIn && (
            <>
              <p className="text-center">Prijavi se kako bi video svoje sačuvane i istekle kupone.</p>
              <Button className="mt-4 ms-0 mx-auto" onClick={handleLogin}>Prijavi se</Button>
            </>
          )}
        </div>
      </FullWidthPageContent>
    </>
  )
}

export default CouponsPage;
