import { ApiResponse, CallAPIReturn, NalogRequestData, RequestData } from "../types/types";
import { NALOG_API, PUBLIC_API } from "../constants/config";
import { version } from './version';

export const callNalogAPI = async <T = CallAPIReturn>(
  method: RequestInit["method"],
  url: string,
  data?: NalogRequestData,
): Promise<T> => {
  const config = {
    method,
    headers: {
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json"
    },
  } as RequestInit;

  if (data) {
    config.body = JSON.stringify(data);
  }

  const fetchTarget = [NALOG_API, url].join("");
  const fetchResult = await fetch(fetchTarget, config);

  return fetchResult.json();
}

export const callPublicAPI = async <T = CallAPIReturn>(
  method: RequestInit["method"],
  url: string,
  params?: URLSearchParams | null,
  data?: RequestData,
  signal?: AbortSignal,
): Promise<ApiResponse<T>> => {
  const config = {
    method,
    headers: {
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "X-Version": version
    },
  } as RequestInit;

  if (data) {
    config.body = JSON.stringify(data);
  }

  if (signal) {
    config.signal = signal;
  }

  const fetchTarget = [PUBLIC_API, url, (params ? `?${params.toString()}` : undefined)].filter(Boolean).join("");
  const fetchResult = await fetch(fetchTarget, config);

  return fetchResult.json();
};
